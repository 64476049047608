.analytics-report-class {
  width: 100%;
  height: calc(100vh - 50px - 44px);
}

.analytics-report-class iframe {
  border: 0px;
}

ul,
ol {
  padding-left: 40px;
}

.ms-DatePicker-goToday {
  right: 14px;
}

.ms-Persona-initials {
  background-color: rgb(64, 128, 128);
  color: rgb(255, 255, 255);
}
.reusableListComponent {
  [data-icon-name='SortUp'],
  [data-icon-name='SortDown'] {
    position: absolute;
    left: 5px;
  }

  [data-icon-name='SwitcherStartEnd'] {
    position: absolute;
    left: 8px;
  }

  .iconOnly {
    [data-icon-name='SortUp'],
    [data-icon-name='SortDown'] {
      position: static;
      margin-left: -1px;
    }

    [data-icon-name='SwitcherStartEnd'] {
      position: static;
      margin-left: 1.5px;
    }
  }
  .ms-DetailsRow {
    width: 100%;
  }
  .ms-DetailsHeader-cellName {
    // margin-left: 14px;
  }

  .noIcon,
  .iconOnly {
    .ms-DetailsHeader-cellName {
      margin-left: 0px;
    }
  }

  // .ms-DetailsHeader {
  //   background-color: transparent;
  // }
}

.noSort {
  .ms-DetailsHeader-cellName {
    margin-left: 0px;
  }
}

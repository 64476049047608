.navigation-panel {
  .ms-Panel-header {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 15px;
    margin: 0;
  }

  .ms-Panel-content {
    padding: 0 10px;
  }

  .create-folder-name {
    margin-right: 5px;
    display: inline-block;
    max-width: 70%;
    align-self: flex-end;
  }

  .create-folder-button {
    display: inline-block;
    align-self: flex-end;
  }

  .create-folder-wrapper {
    display: flex;
    vertical-align: bottom;
    margin-left: 10px;
  }

  .create-folder-cancel {
    color: gray;
    float: right;
  }

  &__list {
    .ms-List-cell {
      cursor: pointer;
      user-select: none;
    }
  }

  .carousel {
    margin-left: 30px;
    padding-top: 20px;
    margin-right: 30px;

    div {
      text-align: center;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }

    .inactive {
      opacity: 0.3;
    }

    .slick-arrow {
      &:before {
        color: gray;
      }
    }
  }
}

.item-wrapper {
  width: 100%;
  display: flex;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  .item-persona {
    display: inline-block;
  }

  .item-name {
  }
}

.selecteditem {
  font-weight: bold;
}

.navigation-panel__list {
  .ms-DetailsRow-cell {
    padding: 0px !important;
  }
}

.actions-container {
  display:  flex;
  gap: 15px;
}
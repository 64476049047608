$light_themePrimary: get('default.palette.themePrimary');
$light_themeLighterAlt: get('default.palette.themeLighterAlt');
$light_themeLighter: get('default.palette.themeLighter');
$light_themeLight: get('default.palette.themeLight');
$light_themeTertiary: get('default.palette.themeTertiary');
$light_themeSecondary: get('default.palette.themeSecondary');
$light_themeDarkAlt: get('default.palette.themeDarkAlt');
$light_themeDark: get('default.palette.themeDark');
$light_themeDarker: get('default.palette.themeDarker');
$light_neutralLighterAlt: get('default.palette.neutralLighterAlt');
$light_neutralLighter: get('default.palette.neutralLighter');
$light_neutralLight: get('default.palette.neutralLight');
$light_neutralQuaternaryAlt: get('default.palette.neutralQuaternaryAlt');
$light_neutralQuaternary: get('default.palette.neutralQuaternary');
$light_neutralTertiaryAlt: get('default.palette.neutralTertiaryAlt');
$light_neutralTertiary: get('default.palette.neutralTertiary');
$light_neutralSecondary: get('default.palette.neutralSecondary');
$light_neutralPrimaryAlt: get('default.palette.neutralPrimaryAlt');
$light_neutralPrimary: get('default.palette.neutralPrimary');
$light_neutralDark: get('default.palette.neutralDark');
$light_black: get('default.palette.black');
$light_white: get('default.palette.white');

$dark_themePrimary: get('darkMode.palette.themePrimary');
$dark_themeLighterAlt: get('darkMode.palette.themeLighterAlt');
$dark_themeLighter: get('darkMode.palette.themeLighter');
$dark_themeLight: get('darkMode.palette.themeLight');
$dark_themeTertiary: get('darkMode.palette.themeTertiary');
$dark_themeSecondary: get('darkMode.palette.themeSecondary');
$dark_themeDarkAlt: get('darkMode.palette.themeDarkAlt');
$dark_themeDark: get('darkMode.palette.themeDark');
$dark_themeDarker: get('darkMode.palette.themeDarker');
$dark_neutralLighterAlt: get('darkMode.palette.neutralLighterAlt');
$dark_neutralLighter: get('darkMode.palette.neutralLighter');
$dark_neutralLight: get('darkMode.palette.neutralLight');
$dark_neutralQuaternaryAlt: get('darkMode.palette.neutralQuaternaryAlt');
$dark_neutralQuaternary: get('darkMode.palette.neutralQuaternary');
$dark_neutralTertiaryAlt: get('darkMode.palette.neutralTertiaryAlt');
$dark_neutralTertiary: get('darkMode.palette.neutralTertiary');
$dark_neutralSecondary: get('darkMode.palette.neutralSecondary');
$dark_neutralPrimaryAlt: get('darkMode.palette.neutralPrimaryAlt');
$dark_neutralPrimary: get('darkMode.palette.neutralPrimary');
$dark_neutralDark: get('darkMode.palette.neutralDark');
$dark_black: get('darkMode.palette.black');
$dark_white: get('darkMode.palette.white');

// @import '/greco-services/src/utilities/Utils.ts'
// GENERAL/BODY STYLING
$bg--light: #f8f9fe;
$bg--dark: #151515;
$primary--light: #cecece;
$primary--dark: #323130;
$primarytext--light: #323130;
$primarytext--dark: #cecece;
$border--light: #eae8e8;
$border--dark: black;
$placeholder--light: #a2a3a0;
$placeholder--dark: #a2a3a0;

//////////////////////////////
// hover  background: #131313;

// FORM STYLING
$bgform--light: white;
$bgform--dark: black;
/////////////////////////

// INPUT FIELDS STYLING
$bginput--light: white;
$bginput--dark: $bgform--dark;
$label-input--light: #323130;
$label-input--dark: #ffffff;
$border-bottom-input--light: $primary--light;
$border-bottom-input--dark: #323130;
$bginput-disabled--light: #f4f4f4;
$bginput-disabled--dark: #151515;
//////////////////////////////////////

// MESSAGE-BAR
$messageBar-bg-light: none;
$messageBar-bg-dark: #131313;

// SECTION HEADER
$section-header-dark: white;
$section-header-light: black;
///////////////////////////

// SIDEBAR
$sidebar-item-light: #d3d3d3;
$sidebar-item-dark: black;

// TABS BUTTONS
$tabs-color-light: $bgform--dark;
$tabs-color-dark: $bgform--light;

// MAIN FIELDS
// text
$mainFieldsTextDisabledLightMode: $light_neutralSecondary;
$mainFieldsTextEnabledLightMode: $light_neutralPrimary;
$mainFieldsTextDisabledDarkMode: $dark_neutralSecondary;
$mainFieldsTextEnabledDarkMode: $dark_neutralPrimary;
// labels
$mainFieldsLabelEnabledLightMode: $light_neutralPrimary;
$mainFieldsLabelEnabledDarkMode: $dark_neutralPrimary;
$mainFieldsLabelDisabledLightMode: $light_neutralSecondary;
$mainFieldsLabelDisabledDarkMode: $dark_neutralSecondary;
// border
$mainFieldsBorderLightMode: $light_neutralPrimary;
$mainFieldsBorderDarkMode: $dark_neutralPrimary;
$mainFieldsBorderDisabledLightMode: $light_neutralSecondary;
$mainFieldsBorderDisabledDarkMode: $dark_neutralSecondary;

// WARNING BOX
$warningBoxBackgroundLightMode: #fed9cc;
$warningBoxBackgroundDarkMode: #d83b01;
$warningBoxIconLightMode: #f54809;
$warningBoxIconDarkMode: #fff;
$deleteWarningBoxBackground-light: #fed9cc;
$deleteWarningBoxBackground-dark: #320d00;
$deleteSuccessBoxBackground-light: #dff6dd;
$deleteSuccessBoxBackground-dark: #132a11;

// LIST OF ALL PROPERTIES
$primary: 'primary';
$primarytext: 'primarytext';
$sectionHeader: 'sectionHeader';
$placeholderColor: 'placeholderColor';
$bg: 'bg';
$bgform: 'bgform';
$bginput: 'bginput';
$bginputDisabled: 'bginputDisabled';
$labelInput: 'labelInput';
$borderBottomInput: 'borderBottomInput';
$border: 'border';
$mainFieldsTextDisabled: 'mainFieldsTextDisabled';
$mainFieldsTextEnabled: 'mainFieldsTextEnabled';
$mainFieldsLabelEnabled: 'mainFieldsLabelEnabled';
$mainFieldsLabelDisabled: 'mainFieldsLabelDisabled';
$mainFieldsBorder: 'mainFieldsBorder';
$mainFieldsBorderDisabled: 'mainFieldsBorderDisabled';
$warningBoxBackground: 'warningBoxBackground';
$warningBoxIcon: 'warningBoxIcon';
$deleteWarningBoxBackground: 'deleteWarningBoxBackground';
$deleteSuccessBoxBackground: 'deleteSuccessBoxBackground';
$sidebarItem: 'sidebarItem';
////////////////////////////////////////

// FUNCTIONALITY
///////////////////////////////////////
$themes: (
  default: (
    primary: $primary--light,
    primarytext: $primarytext--light,
    sectionHeader: $section-header-light,
    bg: $bg--light,
    bgform: $bgform--light,
    bginput: $bginput--light,
    bginputDisabled: $bginput-disabled--light,
    placeholderColor: $placeholder--light,
    labelInput: $label-input--light,
    borderBottomInput: $border-bottom-input--light,
    border: $border--light,
    tabsColor: $tabs-color-light,
    themePrimary: $light_themePrimary,
    themeLighterAlt: $light_themeLighterAlt,
    themeLighter: $light_themeLighter,
    themeLight: $light_themeLight,
    themeTertiary: $light_themeTertiary,
    themeSecondary: $light_themeSecondary,
    themeDarkAlt: $light_themeDarkAlt,
    themeDark: $light_themeDark,
    themeDarker: $light_themeDarker,
    neutralLighterAlt: $light_neutralLighterAlt,
    neutralLighter: $light_neutralLighter,
    neutralLight: $light_neutralLight,
    neutralQuaternaryAlt: $light_neutralQuaternaryAlt,
    neutralQuaternary: $light_neutralQuaternary,
    neutralTertiaryAlt: $light_neutralTertiaryAlt,
    neutralTertiary: $light_neutralTertiary,
    neutralSecondary: $light_neutralSecondary,
    neutralPrimaryAlt: $light_neutralPrimaryAlt,
    neutralPrimary: $light_neutralPrimary,
    neutralDark: $light_neutralDark,
    fabricBlack: $light_black,
    fabricWhite: $light_white,
    mainFieldsTextDisabled: $mainFieldsTextDisabledLightMode,
    mainFieldsTextEnabled: $mainFieldsTextEnabledLightMode,
    mainFieldsLabelEnabled: $mainFieldsLabelEnabledLightMode,
    mainFieldsLabelDisabled: $mainFieldsLabelDisabledLightMode,
    mainFieldsBorder: $mainFieldsBorderLightMode,
    mainFieldsBorderDisabled: $mainFieldsBorderDisabledLightMode,
    warningBoxBackground: $warningBoxBackgroundLightMode,
    warningBoxIcon: $warningBoxIconLightMode,
    deleteWarningBoxBackground: $deleteWarningBoxBackground-light,
    deleteSuccessBoxBackground: $deleteSuccessBoxBackground-light,
    sidebarItem: $sidebar-item-light,
    messageBar: $messageBar-bg-light
  ),
  dark: (
    primary: $primary--dark,
    primarytext: $primarytext--dark,
    sectionHeader: $section-header-dark,
    bg: $bg--dark,
    bgform: $bgform--dark,
    bginput: $bginput--dark,
    bginputDisabled: $bginput-disabled--dark,
    placeholderColor: $placeholder--dark,
    labelInput: $label-input--dark,
    borderBottomInput: $border-bottom-input--dark,
    border: $border--dark,
    tabsColor: $tabs-color-dark,
    themePrimary: $dark_themePrimary,
    themeLighterAlt: $dark_themeLighterAlt,
    themeLighter: $dark_themeLighter,
    themeLight: $dark_themeLight,
    themeTertiary: $dark_themeTertiary,
    themeSecondary: $dark_themeSecondary,
    themeDarkAlt: $dark_themeDarkAlt,
    themeDark: $dark_themeDark,
    themeDarker: $dark_themeDarker,
    neutralLighterAlt: $dark_neutralLighterAlt,
    neutralLighter: $dark_neutralLighter,
    neutralLight: $dark_neutralLight,
    neutralQuaternaryAlt: $dark_neutralQuaternaryAlt,
    neutralQuaternary: $dark_neutralQuaternary,
    neutralTertiaryAlt: $dark_neutralTertiaryAlt,
    neutralTertiary: $dark_neutralTertiary,
    neutralSecondary: $dark_neutralSecondary,
    neutralPrimaryAlt: $dark_neutralPrimaryAlt,
    neutralPrimary: $dark_neutralPrimary,
    neutralDark: $dark_neutralDark,
    fabricBlack: $dark_black,
    fabricWhite: $dark_white,
    mainFieldsTextDisabled: $mainFieldsTextDisabledDarkMode,
    mainFieldsTextEnabled: $mainFieldsTextEnabledDarkMode,
    mainFieldsLabelEnabled: $mainFieldsLabelEnabledDarkMode,
    mainFieldsLabelDisabled: $mainFieldsLabelDisabledDarkMode,
    mainFieldsBorder: $mainFieldsBorderDarkMode,
    mainFieldsBorderDisabled: $mainFieldsBorderDisabledDarkMode,
    warningBoxBackground: $warningBoxBackgroundDarkMode,
    warningBoxIcon: $warningBoxIconDarkMode,
    deleteWarningBoxBackground: $deleteWarningBoxBackground-dark,
    deleteSuccessBoxBackground: $deleteSuccessBoxBackground-dark,
    sidebarItem: $sidebar-item-dark,
    messageBar: $messageBar-bg-dark
  )
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}


.dark-mode .section-tree-sidebar {
  background-color: rgb(0, 0, 0) !important;
  color: white;

  .side-bar-collapse-button {
    &:hover {
      background-color: rgb(37, 37, 37);
    }
  }

  .tree-list__item--closed.active-icon {
    background-color: rgb(37, 37, 37);
  }

  .tree-list__item:hover,
  .tree-list__search button:hover {
    background-color: rgb(37, 37, 37);
  }
}

.section-tree-sidebar {
  position: relative;
  width: 320px;
  max-width: 320px;
  box-sizing: border-box;
  background-color: transparent;
  font-weight: 400;
  padding-bottom: 50px;
  transition-timing-function: linear;
  transition-duration: 0.2s;
  overflow: auto;
  scrollbar-width: thin;

  .tree-list__search {
    min-height: 44px;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c8c6c4;
    border-radius: 1px;
  }

  * {
    outline: none;
  }

  .link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI',
      -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
    &--bold {
      font-weight: bold;
    }
  }

  .side-bar-collapse-button {
    background-color: inherit;
    border-top: 1px solid #ecebe9;
    width: 100%;
    min-height: 50px;
    max-height: 50px;
    position: absolute;
    bottom: 0;
    right: 0;

    &--top {
      position: relative;
    }

    &:hover {
      background-color: #e2e2e2;
    }

    span {
      justify-content: flex-end;
    }

    i {
      margin-right: 12px;
      font-size: 20px;
    }
  }

  // Tree list style
  .tree-list {
    // Search input and toggle button
    &__search {
      .search-folder-icon {
        width: 50px;
        height: 45px;
        border-bottom: 1px solid rgb(221, 221, 221);
        border-radius: 0;

        &:hover {
          background-color: #e2e2e2;
        }
      }
    }

    // UL for list items
    &__items {
      list-style: 'none';
      margin: 0;
      //padding: 10px 10px 0;
      //padding: 0;
    }

    // LI for list item
    &__item {
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &--closed {
        height: 50px;

        &:hover {
          background-color: #e2e2e2;
        }
      }

      // List item icons closed style
      .icon-arrow {
        width: 20px;
        i {
          font-size: 10px;
        }
      }
    }
  }
}

@import '../../../color-themes';

.icon-image {
  height: 44px;
  display: flex;
}

@mixin item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.icon-container-current {
  @include item;
  @include themed() {
    background-color: t($sidebarItem);
  }

  &::before {
    content: '';
    width: 3px;
    height: 100%;
    background-color: #0078d4;
    position: absolute;
    left: 0;
  }
}

.icon-container {
  @include item;
  background-color: transparent;

  &:hover {
    @include themed() {
      background-color: t($sidebarItem);
    }
  }
}

.icon-container-disabled {
  @include item;
  pointer-events: none;
  cursor: auto;
  opacity: 0.3;
}
